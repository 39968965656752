<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>kontakty podle stránek - Upravit <span class="utils__link--underlined text-primary">#{{ this.id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item
        label="E-mail"
          :validate-status="error('email') ? 'error' : ''"
          :help="error('email') || ''"
        >
          <a-input placeholder="E-mail" v-decorator="['email', {rules: [{max: 100, message: 'E-mail nemůže být delší než 100 znaků'}, {required: true, message: 'E-mail musí být vyplněn!'}]}]"/>
        </a-form-item>
        <a-form-item
        label="Telefon"
          :validate-status="error('telephone_number') ? 'error' : ''"
          :help="error('telephone_number') || ''"
        >
          <a-input placeholder="Telefon" v-decorator="['telephone_number', {rules: [{max: 20, message: 'Telefon nemůže být delší než 20 znaků'}, {required: true, message: 'Telefon musí být vyplněn!'}]}]"/>
        </a-form-item>
        <a-form-item label="Zobrazit v e-shopu?">
          <a-checkbox v-decorator="['is_ecommerce', { valuePropName: 'checked' }]"/>
        </a-form-item>
        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Upravit</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../services/forms'

export default {
  components: { ActionTools },
  data: function () {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/company-contacts',
          title: 'Seznam',
        },
      ],
      form: this.$form.createForm(this),
      id: this.$route.params.id,
    }
  },
  computed: {
    detail: function () {
      return this.$store.getters['companyContacts/getDetail']
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('companyContacts/put', { id: this.id, item: this.getData() })
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      return {
        email: this.form.getFieldValue('email'),
        telephone_number: this.form.getFieldValue('telephone_number'),
        is_ecommerce: this.form.getFieldValue('is_ecommerce'),
      }
    },
    handleInit() {
      this.$store.dispatch('companyContacts/getOne', this.id)
        .then(() => {
          this.form.setFieldsValue({
            email: this.detail.email,
            telephone_number: this.detail.telephone_number,
            is_ecommerce: this.detail.is_ecommerce,
          })
          this.form.validateFields()
        })
        .catch(() => {})
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    refreshComponent(id) {
      this.id = id
    },
  },
  created() {
    this.handleInit()
  },
  watch: {
    '$route.params.id'(newId, oldId) {
      this.refreshComponent(newId)
      this.handleInit()
    },
  },
}
</script>
